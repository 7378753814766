
const GetDeviceContextMenu = () => {
    return [
        {
            id: 1,
            title: 'Edit NLISID',
            subtitle: '',
            onClick: () => {
                console.log('Previewing transfer');
            },
        },
        {
            id: 2,
            title: 'Edit RFID',
            subtitle: '',
            onClick: () => {
                console.log('Previewing transfer');
            },
        },
        {
            id: 3,
            title: 'Delete',
            className: 'text-error',
            onClick: () => {
                // const { current } = deleteModalRef;

                // if (current) {
                //     current.show();
                // }
            },
        }
    ].coalesce();
};

export default GetDeviceContextMenu;