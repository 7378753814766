import { ValidatedDevice } from '@common/context/DevicesContext';
import Loader from '@components/Loader';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import ValidationCard from '@containers/Consignments/ValidationCard';
import useDeviceManager from '@effects/useDeviceManager';
import { DeviceValidationStatus } from '@utils/enums';
import React, { useEffect, useState } from 'react';

import DeviceTable from './DeviceTable';
import DeviceTableFilters from './DeviceTableFilters';

const DeviceList: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({ consignment }) => {
    const { validatedDevices, getValidationStatus } = useDeviceManager();
    const [sortedDevices, setSortedDevices] = useState<ValidatedDevice[]>([]);
    const [devicesWithIssues, setDevicesWithIssues] = useState<{
        warnings: ValidatedDevice[];
        errors: ValidatedDevice[];
    }>({
        warnings: [],
        errors: [],
    });

    useEffect(() => {
        if (!validatedDevices.value) return;

        const newDevicesWithIssues = {
            warnings: [] as ValidatedDevice[],
            errors: [] as ValidatedDevice[],
        };

        // Group devices by validation status
        validatedDevices.value.forEach((device) => {
            const status = getValidationStatus(device, consignment);
            if (status === DeviceValidationStatus.WARNING) {
                newDevicesWithIssues.warnings.push(device);
            } else if (status === DeviceValidationStatus.ERROR) {
                newDevicesWithIssues.errors.push(device);
            }
        });

        setDevicesWithIssues(newDevicesWithIssues);
        setSortedDevices([
            ...newDevicesWithIssues.errors,
            ...newDevicesWithIssues.warnings,
            ...[...validatedDevices.value].filter((device) => getValidationStatus(device, consignment) === DeviceValidationStatus.VALIDATED),
        ]);
    }, [consignment, validatedDevices.value, getValidationStatus]);

    if (sortedDevices.length > 0) {
        const totalDevices = validatedDevices.value.size;
        const totalIssues = devicesWithIssues.errors.length + devicesWithIssues.warnings.length;

        return (
            <div className="p-bottom-16">
                <div className="p-v-8">
                    <h2 className="p-v-16">NLIS Device Details</h2>
                    <p>
                        Review your list below and make any minor edits by clicking 'Options', for any complex issues contact{' '}
                        <a href="https://www.integritysystems.com.au/identification--traceability/technical-support/" target="_blank" rel="noopener noreferrer">
                            NLIS Support
                        </a>
                        .
                    </p>
                </div>

                <h2 className="p-v-16 total">{totalIssues > 0 ? `${totalIssues}/${totalDevices} NLIS devices` : `${totalDevices} NLIS devices`}</h2>

                {devicesWithIssues.warnings.length === 0 && devicesWithIssues.errors.length === 0 && <ValidationCard status="success" />}
                {devicesWithIssues.errors.length > 0 && <ValidationCard status="error" issueCount={devicesWithIssues.errors.length} />}
                {devicesWithIssues.warnings.length > 0 && <ValidationCard status="warning" issueCount={devicesWithIssues.warnings.length} />}

                <DeviceTableFilters />

                <DeviceTable validatedDevices={sortedDevices} consignment={consignment} />

                <style jsx>
                    {`
                        @import 'mixins';
                        @import 'vars';

                        .total {
                            color: $color-secondary;
                        }
                    `}
                </style>
            </div>
        );
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default DeviceList;
