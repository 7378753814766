import { useDevicesContext, ValidatedDevice } from '@common/context/DevicesContext';
import { useUserState } from '@common/context/userContext';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import SaveOrUpdateDevices, { saveOrUpdateDevicesMutation } from '@containers/Consignments/mutations/saveOrUpdateDevices';
import { DeviceResponseStatus, DeviceValidationStatus } from '@utils/enums';
import { handleFileRead } from '@utils/file-ops';
import { createRef, useCallback, useState } from 'react';
import { DropzoneRef, ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import { useMutation } from 'relay-hooks';
export enum CustomErrorCode {
    DeviceLimitExceeded = 'TagLimitExceeded', // Custom error for exceeding tag limit
}

const DeviceErrorCode = {
    ...ErrorCode,
    ...CustomErrorCode,
} as const;

// type DeviceErrorCodeType = keyof typeof DeviceErrorCode;
type DeviceErrorCodeType = CustomErrorCode | ErrorCode;
/**
 * Custom hook to manage files from react-dropzone
 * @returns {Object} An object containing the files, dropzoneProps, and utility functions
 */
const useDeviceManager = () => {
    const { acceptedFile, setAcceptedFile, deviceIds, setDeviceIds, errorMessage, setErrorMessage, validatedDevices, setValidatedDevices } = useDevicesContext();
    const [rawMnaualDeviceIds, setRawManualDeviceIds] = useState<string>('');
    const [inDropZone, setInDropZone] = useState(false);

    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const [validateDeviceMutate, { loading: validationLoading }] = useMutation(saveOrUpdateDevicesMutation);
    const dropzoneRef = createRef<DropzoneRef>();

    const openDialog = () => {
        if (dropzoneRef.current && acceptedFile.length === 0) {
            dropzoneRef.current.open();
        }
    };

    const onDropAccepted = () => setInDropZone(false);
    const onDragOver = () => setInDropZone(true);
    const onDragLeave = () => setInDropZone(false);

    const processFile = async (acceptedFiles?: FileWithPath[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setAcceptedFile(acceptedFiles);
            handleFileRead(
                acceptedFiles[0], // Pass the file
                (values) => {
                    setDeviceIds(new Set(values)); // Update state with the values
                    setErrorMessage(''); // Clear any previous errors
                },
                (err) => {
                    setErrorMessage(err); // Handle any errors
                }
            );
        }
    };

    const getErrorMsg = (errorCode: DeviceErrorCodeType, manualDeviceId?: string) => {
        switch (errorCode) {
            case DeviceErrorCode.FileInvalidType:
                return `Unsupported file format. Please use a .csv or .txt file instead.`;
            case DeviceErrorCode.FileTooLarge:
                return `File is larger than 1MB`;
            case DeviceErrorCode.DeviceLimitExceeded:
                return `The 'Add manually' option has reached its maximum limit of 1,000 NLIS devices. To proceed, you can either remove an existing tag or select the 'Upload your file' option, this alternative allows you to add more number of NLIS devices.`;
            default:
                return `File Upload failed, Please try again.`;
        }
    };

    const onDropRejected = (fileRejections: FileRejection[]) => {
        setInDropZone(false);
        if (fileRejections.length) {
            const { errors } = fileRejections[0];
            const errMsg = getErrorMsg(errors[0].code as DeviceErrorCodeType);
            setErrorMessage(errMsg);
        }
    };

    // Utility function to clear all files
    const clearFiles = useCallback(() => {
        setAcceptedFile([]);
        setDeviceIds(new Set());
        setRawManualDeviceIds('');
        setErrorMessage('');
        setValidatedDevices({
            value: new Set<ValidatedDevice>(),
            totalDevices: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processManualDevices = (manualDeviceIDs?: string) => {
        if (!manualDeviceIDs) {
            setRawManualDeviceIds('');
            setDeviceIds(new Set());
            setErrorMessage('');
            return;
        }
        setRawManualDeviceIds(manualDeviceIDs);
        const lines = manualDeviceIDs
            .split('\n')
            .map((line) => line.trim())
            .filter(Boolean); // remove empty lines

        const newTags = new Set<string>();
        let errMsg = '';
        for (let i = 0; i < lines.length; i++) {
            const trimmedTag = lines[i]; // Use the whole line as one device ID
            if (trimmedTag) {
                newTags.add(trimmedTag);
                if (newTags.size > 1000) {
                    errMsg = getErrorMsg(DeviceErrorCode.DeviceLimitExceeded, trimmedTag);
                    break;
                }
            }
        }
        setDeviceIds(newTags);
        setErrorMessage(errMsg);
    };

    const validateAndAddDevices = async (consignmentNumber: string) => {
        try {
            if (deviceIds.size > 0) {
                const response = await SaveOrUpdateDevices(
                    validateDeviceMutate,
                    {
                        number: consignmentNumber,
                        action: 'Add',
                        deviceIds: Array.from(deviceIds),
                    },
                    envdAccountId
                );
                if (response?.value && response?.value?.length > 0) {
                    setValidatedDevices({
                        value: new Set<ValidatedDevice>(response.value),
                        totalDevices: Number(response.totalDevices) ?? 0,
                    });
                    // ToDo: remove reload and have better implementation
                    // window.location.reload();
                }
            }
        } catch (error) {
            setErrorMessage(`validation failed: ${error}`);
        }
    };

    const getValidationStatus = useCallback((device: ValidatedDevice, consignment: ConsignmentDetailQueryResponse['consignment']): DeviceValidationStatus => {
        if (device.status === DeviceResponseStatus.NOT_FOUND) {
            return DeviceValidationStatus.ERROR;
        }

        if (device.species.toLowerCase() !== consignment?.species.toLowerCase() || device.registeredTo !== consignment.origin.pic || device.registeredTo.startsWith('EUSY') || device.deceased) {
            return DeviceValidationStatus.WARNING;
        }

        return DeviceValidationStatus.VALIDATED;
    }, []);

    return {
        validationLoading,
        validatedDevices,
        rawMnaualDeviceIds,
        deviceIds,
        dropzoneRef,
        openDialog,
        acceptedFile,
        errorMessage,
        inDropZone,
        onDropRejected,
        onDropAccepted,
        onDragOver,
        onDragLeave,
        processFile,
        processManualDevices,
        clearFiles,
        validateAndAddDevices,
        getValidationStatus,
    };
};

export default useDeviceManager;
