/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeviceStatus = "ACTIVE" | "INACTIVE" | "NOT_FOUND" | "%future added value";
export type ValidatedDeviceListQueryVariables = {
    envdAccountId: string;
    consignmentNumber: string;
};
export type ValidatedDeviceListQueryResponse = {
    readonly devices: {
        readonly value: ReadonlyArray<{
            readonly nLISID: string | null;
            readonly rFID: string | null;
            readonly registeredTo: string | null;
            readonly species: string | null;
            readonly status: DeviceStatus;
            readonly deceased: boolean | null;
        } | null> | null;
        readonly totalDevices: string | null;
    } | null;
};
export type ValidatedDeviceListQuery = {
    readonly response: ValidatedDeviceListQueryResponse;
    readonly variables: ValidatedDeviceListQueryVariables;
};



/*
query ValidatedDeviceListQuery(
  $envdAccountId: String!
  $consignmentNumber: String!
) {
  devices(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {
    value {
      nLISID
      rFID
      registeredTo
      species
      status
      deceased
    }
    totalDevices
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "consignmentNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consignmentNumber",
        "variableName": "consignmentNumber"
      },
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      }
    ],
    "concreteType": "DeviceType",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DevicesType",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nLISID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rFID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "species",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deceased",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalDevices",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidatedDeviceListQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ValidatedDeviceListQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c203445c4106519122d935d56dc71477",
    "id": null,
    "metadata": {},
    "name": "ValidatedDeviceListQuery",
    "operationKind": "query",
    "text": "query ValidatedDeviceListQuery(\n  $envdAccountId: String!\n  $consignmentNumber: String!\n) {\n  devices(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {\n    value {\n      nLISID\n      rFID\n      registeredTo\n      species\n      status\n      deceased\n    }\n    totalDevices\n  }\n}\n"
  }
};
})();
(node as any).hash = '91bbfd3e8b4119f0e66c95280abc5fe2';
export default node;
